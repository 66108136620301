import React, { useState } from 'react';
import { 
  Book, Code, Users, Brain, Clock, Award, Menu, X, ChevronRight, 
  ChevronDown, Mail, Phone, MapPin, PlayCircle, FileText, CheckCircle,
  Timer, BookOpen, Lock
} from 'lucide-react';

const TrainingWebsite2 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [expandedModule, setExpandedModule] = useState(null);
  const [previewContent, setPreviewContent] = useState(null);

  const courses = [
    {
      title: "Fundamentals of AI",
      description: "Introduction to AI concepts, machine learning basics, and neural networks",
      duration: "4 weeks",
      icon: <Brain className="w-6 h-6" />
    },
    {
      title: "Practical GenAI",
      description: "Hands-on experience with popular generative AI models and tools",
      duration: "6 weeks",
      icon: <Code className="w-6 h-6" />
    },
    {
      title: "Advanced Applications",
      description: "Real-world applications, fine-tuning, and deployment strategies",
      duration: "8 weeks",
      icon: <Book className="w-6 h-6" />
    }
  ];

  const courseContent = [
    {
      module: 1,
      title: "Introduction to AI and Generative AI",
      duration: "1 week",
      description: "Get started with the fundamentals of AI and understand what makes it generative.",
      topics: [
        "What is Artificial Intelligence?",
        "Evolution and brief history of AI",
        "What makes AI &lquot;generative&rquot;?",
        "Real-world examples and applications",
        "Impact across industries"
      ],
      resources: 3,
      exercises: 2
    },
    {
      module: 2,
      title: "Understanding How Generative AI Works",
      duration: "1 week",
      description: "Deep dive into the technical foundations of generative AI systems.",
      topics: [
        "Basic concepts of machine learning",
        "Supervised vs. unsupervised learning",
        "Neural networks simplified",
        "Training data and patterns",
        "Basic data processing concepts",
        "Understanding tokens and embeddings"
      ],
      resources: 4,
      exercises: 3
    },
    {
      module: 3,
      title: "Types of Generative Models",
      duration: "1 week",
      description: "Explore different types of generative models and their applications.",
      topics: [
        "Large Language Models (LLMs)",
        "Generative vs. Discriminative Models",
        "GANs, VAEs, and Diffusion Models",
        "Understanding model architectures",
        "The generation process: from input to output"
      ],
      resources: 5,
      exercises: 3
    },
    {
      module: 4,
      title: "Exploring Different Generative AI Applications",
      duration: "1 week",
      description: "Learn about various applications of generative AI across different domains.",
      topics: [
        "Text generation and LLMs",
        "Image generation and manipulation",
        "Audio generation",
        "Video generation",
        "Code generation",
        "Practical demonstrations of each type"
      ],
      resources: 6,
      exercises: 4
    },
    {
      module: 5,
      title: "Popular Generative AI Tools",
      duration: "1 week",
      description: "Get hands-on experience with popular generative AI tools and platforms.",
      topics: [
        "ChatGPT and other chatbots",
        "DALL-E, Midjourney, Stable Diffusion",
        "GitHub Copilot",
        "Voice AI tools",
        "Free and accessible AI platforms",
        "Hands-on practice sessions"
      ],
      resources: 4,
      exercises: 5
    },
    {
      module: 6,
      title: "Mastering Prompt Engineering",
      duration: "1 week",
      description: "Learn the art and science of crafting effective prompts for AI models.",
      topics: [
        "Understanding prompt basics",
        "What makes a good prompt?",
        "Common prompt patterns",
        "Best practices for interacting with AI models",
        "Tips and tricks for better results",
        "Practice exercises with examples"
      ],
      resources: 5,
      exercises: 6
    },
    {
      module: 7,
      title: "Practical Applications and Use Cases",
      duration: "1 week",
      description: "Discover real-world applications and implementation strategies.",
      topics: [
        "Personal applications",
        "Business use cases",
        "Educational applications",
        "Creative projects (writing, design, music)",
        "Scientific and research applications",
        "Productivity enhancement"
      ],
      resources: 4,
      exercises: 3
    },
    {
      module: 8,
      title: "Ethics and Responsible AI Usage",
      duration: "1 week",
      description: "Understanding the ethical implications and responsible use of AI.",
      topics: [
        "Bias in AI models",
        "Privacy and data protection",
        "Copyright and intellectual property",
        "Responsible AI usage",
        "Misinformation and deep fakes",
        "Impact on society and careers"
      ],
      resources: 3,
      exercises: 2
    },
    {
      module: 9,
      title: "Limitations and Challenges",
      duration: "1 week",
      description: "Explore the current limitations and challenges in generative AI.",
      topics: [
        "Current technological limitations",
        "Common misconceptions",
        "When not to use AI",
        "Understanding AI hallucinations",
        "Importance of fact-checking",
        "Safeguards in AI usage"
      ],
      resources: 4,
      exercises: 2
    },
    {
      module: 10,
      title: "Projects and Hands-on Learning",
      duration: "1 week",
      description: "Apply your knowledge through practical projects and exercises.",
      topics: [
        "Setting up accounts on free platforms",
        "Creating a simple chatbot",
        "Building an image generator",
        "Making a music generator",
        "Interactive demonstrations",
        "Guided exercises"
      ],
      resources: 6,
      exercises: 5
    },
    {
      module: 11,
      title: "Future of Generative AI",
      duration: "1 week",
      description: "Explore emerging trends and future possibilities in generative AI.",
      topics: [
        "Emerging trends and technologies",
        "Potential developments",
        "Impact on various industries",
        "Required skills for the AI-enabled future",
        "Career opportunities in AI"
      ],
      resources: 3,
      exercises: 2
    },
    {
      module: 12,
      title: "Resources and Continuous Learning",
      duration: "1 week",
      description: "Get equipped with resources for continued learning and growth.",
      topics: [
        "Recommended books and courses",
        "Online resources and tutorials",
        "Community engagement",
        "Forums for ongoing learning",
        "Next steps in AI education"
      ],
      resources: 5,
      exercises: 2
    }
  ];

  const PreviewModal = ({ content, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold">Module {content.module}: {content.title}</h3>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X className="w-6 h-6" />
            </button>
          </div>
          <div className="space-y-4">
            <p className="text-gray-600">{content.description}</p>
            <div className="flex space-x-4">
              <div className="flex items-center">
                <Timer className="w-4 h-4 mr-2 text-blue-600" />
                <span>{content.duration}</span>
              </div>
              <div className="flex items-center">
                <BookOpen className="w-4 h-4 mr-2 text-blue-600" />
                <span>{content.resources} resources</span>
              </div>
              <div className="flex items-center">
                <FileText className="w-4 h-4 mr-2 text-blue-600" />
                <span>{content.exercises} exercises</span>
              </div>
            </div>
            <div>
              <h4 className="font-semibold mb-2">Topics Covered:</h4>
              <ul className="space-y-2">
                {content.topics.map((topic, index) => (
                  <li key={index} className="flex items-center">
                    <CheckCircle className="w-4 h-4 mr-2 text-green-500" />
                    {topic}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-6">
              <button className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition flex items-center">
                <PlayCircle className="w-4 h-4 mr-2" />
                Preview Module
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation - Same as before */}
      <nav className="bg-white shadow-lg">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center space-x-2">
              <Brain className="w-8 h-8 text-blue-600" />
              <span className="font-bold text-xl">GenAI Academy</span>
            </div>
            
            <div className="hidden md:flex space-x-8">
              <a href="#home" className="text-gray-700 hover:text-blue-600">Home</a>
              <a href="#curriculum" className="text-gray-700 hover:text-blue-600">Course Curriculum</a>
              <a href="#features" className="text-gray-700 hover:text-blue-600">Features</a>
              <a href="#contact" className="text-gray-700 hover:text-blue-600">Contact</a>
            </div>

            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden text-gray-700">
              {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>
        </div>

        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <a href="#home" className="block px-3 py-2 text-gray-700 hover:bg-gray-100">Home</a>
              <a href="#curriculum" className="block px-3 py-2 text-gray-700 hover:bg-gray-100">Course Curriculum</a>
              <a href="#features" className="block px-3 py-2 text-gray-700 hover:bg-gray-100">Features</a>
              <a href="#contact" className="block px-3 py-2 text-gray-700 hover:bg-gray-100">Contact</a>
            </div>
          </div>
        )}
      </nav>
      {/* ... */}
      {/* Hero Section */}
      <div className="bg-blue-600 text-white py-20">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">Master Generative AI</h1>
          <p className="text-xl mb-8">Comprehensive training from basics to advanced applications</p>
          <button className="bg-white text-blue-600 px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition">
            Get Started
          </button>
        </div>
      </div>

      {/* Features Section */}
      <div id="features" className="py-16 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose Us</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="p-6 border rounded-lg hover:shadow-lg transition">
              <Clock className="w-12 h-12 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Flexible Learning</h3>
              <p className="text-gray-600">Learn at your own pace with structured modules</p>
            </div>
            <div className="p-6 border rounded-lg hover:shadow-lg transition">
              <Users className="w-12 h-12 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Expert Instructors</h3>
              <p className="text-gray-600">Learn from industry professionals</p>
            </div>
            <div className="p-6 border rounded-lg hover:shadow-lg transition">
              <Award className="w-12 h-12 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Certification</h3>
              <p className="text-gray-600">Earn recognized certificates</p>
            </div>
            <div className="p-6 border rounded-lg hover:shadow-lg transition">
              <Award className="w-12 h-12 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Placements</h3>
              <p className="text-gray-600">Placement assistance to help our students successfully connect with job opportunities after completing their training</p>
            </div>
          </div>
        </div>
      </div>

      {/* Courses Section */}


      {/* Course Curriculum Section */}
      <div id="curriculum" className="py-16 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-6">Course Curriculum</h2>
          <p className="text-center text-gray-600 mb-12">12 weeks of comprehensive training in Generative AI</p>
          
          <div className="space-y-4">
            {courseContent.map((module) => (
              <div key={module.module} className="border rounded-lg overflow-hidden hover:shadow-md transition">
                <button
                  className="w-full px-6 py-4 bg-gray-50 hover:bg-gray-100 flex items-center justify-between"
                  onClick={() => setExpandedModule(expandedModule === module.module ? null : module.module)}
                >
                  <div className="flex items-center space-x-4">
                    <div className="bg-blue-600 text-white w-8 h-8 rounded-full flex items-center justify-center">
                      {module.module}
                    </div>
                    <div className="text-left">
                      <h3 className="font-semibold">{module.title}</h3>
                      <p className="text-sm text-gray-500">{module.duration}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <button 
                      onClick={(e) => {
                        e.stopPropagation();
                        setPreviewContent(module);
                      }}
                      className="text-blue-600 hover:text-blue-700 flex items-center"
                    >
                      <PlayCircle className="w-5 h-5 mr-1" />
                      <span className="hidden md:inline">Preview</span>
                    </button>
                    <ChevronDown 
                      className={`w-5 h-5 transition-transform ${expandedModule === module.module ? 'transform rotate-180' : ''}`}
                    />
                  </div>
                </button>
                {expandedModule === module.module && (
                  <div className="px-6 py-4 bg-white">
                    <p className="text-gray-600 mb-4">{module.description}</p>
                    <div className="flex space-x-4 mb-4">
                      <div className="flex items-center">
                        <BookOpen className="w-4 h-4 mr-2 text-blue-600" />
                        <span>{module.resources} resources</span>
                      </div>
                      <div className="flex items-center">
                        <FileText className="w-4 h-4 mr-2 text-blue-600" />
                        <span>{module.exercises} exercises</span>
                      </div>
                    </div>
                    <ul className="space-y-2">
                      {module.topics.map((topic, index) => (
                        <li key={index} className="flex items-center space-x-2">
                          <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0" />
                          <span>{topic}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Course Summary */}
          <div className="mt-12 p-6 bg-blue-50 rounded-lg">
            <h3 className="text-xl font-semibold mb-4">Course Highlights</h3>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="flex items-start space-x-3">
                <Timer className="w-6 h-6 text-blue-600" />
                <div>
                  <h4 className="font-semibold">Duration</h4>
                  <p className="text-gray-600">12 weeks, flexible schedule</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <BookOpen className="w-6 h-6 text-blue-600" />
                <div>
                  <h4 className="font-semibold">Resources</h4>
                  <p className="text-gray-600">50+ learning materials</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <FileText className="w-6 h-6 text-blue-600" />
                <div>
                  <h4 className="font-semibold">Projects</h4>
                  <p className="text-gray-600">25+ hands-on exercises</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {previewContent && (
        <PreviewModal 
          content={previewContent} 
          onClose={() => setPreviewContent(null)} 
        />
      )}


      {/* Contact Section */}
      <div id="contact" className="py-16 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Contact Us</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <Mail className="w-6 h-6 text-blue-600" />
                <span>Jay Bobba</span>
              </div>
              <div className="flex items-center space-x-3">
                <Phone className="w-6 h-6 text-blue-600" />
                <span>+1 (630) 566-9333</span>
              </div>
              <div className="flex items-center space-x-3">
                <MapPin className="w-6 h-6 text-blue-600" />
                <span>Schaumburg</span>
              </div>
            </div>
            <form className="space-y-4">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
              <input
                type="email"
                placeholder="Your Email"
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
              <textarea
                placeholder="Your Message"
                rows="4"
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
              ></textarea>
              <button className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>


      {/* Rest of the components (Contact, Footer, etc.) remain the same */}
      {/* ... */}
      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-2 mb-4 md:mb-0">
              <Brain className="w-8 h-8" />
              <span className="font-bold text-xl">GenAI Academy</span>
            </div>
            <div className="text-sm text-gray-400">
              © 2024 GenAI Academy. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TrainingWebsite2;