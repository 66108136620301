import logo from './logo.svg';
import './App.css';
import './index.css';
//import TrainingPlatform from './components/TrainingPlatform';
import Header from './components/Header';
import TrainingWebsite from './components/TrainingWebsite';
import TrainingWebsite2 from './components/TrainingWebsite2';

function App() {
 //return (<div><Header /><TrainingPlatform /></div>);
 return (
    <TrainingWebsite2 />
 )
}

export default App;
