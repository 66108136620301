import React, { useState } from 'react';
import { Menu, Share2 } from 'lucide-react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  const menuItems = ['HOME', 'ABOUT US', 'FAQs', 'COURSES', 'VIDEOS', 'STUDENT FEEDBACK', 'BLOG', 'CONTACT US'];
  
  return (
    <header className="fixed top-0 w-full bg-[#2874c5] text-white z-50">
      <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center gap-4">
          <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center text-[#2874c5] font-bold">
            E
          </div>
          <span className="text-xl hidden md:block">EmpireDataSystems</span>
        </div>
        
        <nav className="hidden md:flex gap-8">
          {menuItems.map((item) => (
            <a key={item} href="#" className="hover:text-gray-200">
              {item}
            </a>
          ))}
        </nav>
        
        <div className="flex items-center gap-4">
          <Share2 className="w-6 h-6 cursor-pointer hover:text-gray-200" />
          <Menu 
            className="w-6 h-6 cursor-pointer md:hidden"
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
      </div>
      
      {isOpen && (
        <div className="md:hidden px-4 py-2 bg-[#1e5c9e]">
          {menuItems.map((item) => (
            <a key={item} href="#" className="block py-2 hover:text-gray-200">
              {item}
            </a>
          ))}
        </div>
      )}
    </header>
  );
};

export default Header;